import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { StaticImage } from "gatsby-plugin-image";
import Typography from "../components/Typography/Typography";
import MainLayout from "../components/mainlayout";
import { mobileViewBreakpoint } from "../components/helper";
import Text from "../components/Text/Text";
import Button from "../components/Button/Button";

import Summary from "../components/CaseStudy/Summary/Summary";

const useStyles = makeStyles((theme) => ({
  container: {
    boxSizing: "border-box",
    padding: "0px 32px",
    maxWidth: "1234px",
    margin: "80px auto 80px auto",
    "& p": {
      marginBottom: "20px",
    },
    "& > h2": {
      marginBottom: "28px",
      color: "#122044",
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginBottom: "24px",
        padding: "0px 16px",
      },
    },
    "& > h3": {
      color: "#122044",
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        padding: "0px 16px",
      },
    },
    "& > h5": {
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginBottom: "24px",
      },
    },

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "0px 0px 40px 0px",
      margin: "60px auto 0px auto",
    },
  },
  root: {
    margin: "0px -16px",
  },
}));
const Article = () => {
  const classes = useStyles();

  return (
    <MainLayout
      title={"Transportation Management Services"}
      h1
      banner={
        <StaticImage
          src="../assets/images/transportation/TRANSPORT MANAGEMENT SERVICES - HEADER.jpg"
          draggable="false"
        />
      }
      breadcrumbs={[{ label: "Transportation Management Service" }]}
      metaTitle={"Transportation Management System & Services"}
      metaDescription={
        "MOTUS | ONE provides the best transportation management system (TMS). As service experts, we offer passenger & ground transport services in UAE. Click now."
      }
    >
      <div className={classes.root}>
        <div className={classes.container}>
          <Typography component={"h2"} customVariant={"h2Bold"}>
            All your transport needs – covered
          </Typography>
          <Text
            isFlipped={true}
            text={
              <Typography component="p" customVariant={"h5Regular"}>
                <p>
                  MOTUS | ONE plans, implements and manages transport systems of
                  any size, type and complexity. Use our expertise where it will
                  benefit you most – as strategic partner, ground-level operator
                  or both.
                </p>

                <p>
                  For us, every client and project are unique. And by focusing
                  on your specific needs, we can bring new efficiencies to all
                  types of transport provision – from one-off events to Artist
                  Transport and bespoke solutions for your corporate, VIP and
                  foreign delegate passengers.
                </p>
              </Typography>
            }
          />

          <Typography component={"h3"} customVariant={"h3Bold"}>
            From ground ops to high-level strategy
          </Typography>

          <Text
            isFlipped={true}
            text={
              <Typography component="p" customVariant={"h5Regular"}>
                <p>
                  We simplify all aspects of operations – from traffic
                  management and liaison with local authorities to vehicle
                  management and staff training. Above all, we deliver safe and
                  reliable transport with a meticulous approach to passenger
                  care and comfort.
                </p>

                <p>
                  You can partner with us strategically too. We can collaborate
                  at all stages of planning, including your concept of
                  operations (CONOP) and working operations plan (OPLAN).
                </p>
              </Typography>
            }
          />
        </div>

        <Summary
          title={"Tailor-made services for shuttle and VIP passengers"}
          fullHeight={false}
          isFlipped={true}
          image={
            <StaticImage
              src="../assets/images/transportation/Shuttle_Service.jpg"
              draggable="false"
            />
          }
          link={undefined}
          customWidth={640}
          description={
            <>
              <p>
                Tell us your requirements and we’ll plan a bespoke solution
                using shuttle buses, sole vehicles and fleets. We simplify the
                entire operation, with rigorous curb side management and driver
                control. And we’ll configure efficient systems that span all
                journey types – from airports, hotels and entertainment venues
                to corporate and industrial settings.
              </p>
              <p>
                For VIPs, we offer secure convoys and chauffeur services
                delivered with discretion and the utmost professionalism.
              </p>
            </>
          }
        />

        <Summary
          title={"Effective and efficient staff and production transport"}
          fullHeight={false}
          isFlipped={false}
          customWidth={600}
          customHeight={960}
          image={
            <StaticImage
              src="../assets/images/about/transport.jpg"
              draggable="false"
            />
          }
          link={undefined}
          description={
            <>
              <p>
                No matter whether you’re moving five or 5,000 staff, so many
                issues are the same. Busy urban environments, changing traffic
                conditions, flexible working patterns – they all add complexity
                and can jeopardize services.
              </p>
              <p>
                We mitigate these risks with effective contingency planning and
                highly responsive communications. We’ll keep your teams informed
                at all times and enable you to manage costs and streamline
                operations.
              </p>
              <p>
                Your ops teams need the right technology. Our systems ensure
                everyone has full visibility and ability to respond – from
                drivers and dispatchers to supervisors and management.
              </p>

              <Typography component={"h3"} customVariant={"h3Bold"}>
                Come to us for:
              </Typography>

              <ul>
                <li>
                  Strategic planning and ops models that mitigate risk and
                  reduce cost
                </li>
                <li>
                  Optimal customer service based on technology, clear
                  communications and responsiveness
                </li>
                <li>Fleet management that frees up your time and resources</li>
              </ul>
            </>
          }
        />

        <Summary
          title={"Fleet management – simplified"}
          fullHeight={false}
          isFlipped={true}
          image={
            <StaticImage
              src="../assets/images/transportation/TRANSPORT MANAGEMENT SERVICES - FLEET MANAGEMENT.jpg"
              draggable="false"
            />
          }
          link={undefined}
          customWidth={640}
          customHeight={780}
          description={
            <>
              <p>
                Use our procurement expertise to select, specify, procure and
                integrate the right new vehicles for your fleet. Give us your
                requirements and we’ll source and configure vehicles to reduce
                costs and minimize your environmental footprint.
              </p>
              <p>
                We can equip all your vehicles with high-spec hardware and
                software, including on-board cameras, inspection monitoring,
                route-planning, vehicle tracking and passenger counting and
                comms. These integrated systems can bring radical new
                efficiencies to all fleet-management scenarios.
              </p>

              <Typography component={"h3"} customVariant={"h3Bold"}>
                Count on us to deliver:
              </Typography>

              <ul>
                <li>Shuttle and fleet-vehicle specification and sourcing</li>
                <li>Selection and acquisition of VIP vehicles</li>
                <li>Fleet ops optimization and vehicle branding</li>
              </ul>
            </>
          }
        />
      </div>
    </MainLayout>
  );
};

export default Article;
